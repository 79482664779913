import { FeaturePermissionToggle as FeaturePermissionToggleEnum } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { User } from '@fullyops/legacy/data/models/v0/User';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject } from 'rxjs';
import { TenantControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-tenant-controller-v2.service';
import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UiAuthService, PermissionType } from '@fullyops/legacy/ui';
import { Router } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import {
  faAddressBook,
  faBoxOpen,
  faBoxesStacked,
  faBuilding,
  faCircleArrowUp,
  faCircleNodes,
  faDatabase,
  faFileImport,
  faFileLines,
  faGear,
  faGears,
  faHelmetSafety,
  faMicroscope,
  faKey,
  faList,
  faListCheck,
  faPowerOff,
  faReceipt,
  faScrewdriverWrench,
  faTags,
  faTruckField,
  faUser,
  faUserGear,
  faUserLock,
  faUsers,
  faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import { TenantResponse } from '@fullyops/legacy/data/api/types/Tenant';
import { environment } from '../../../../environments/environment';
import { FeaturePermissionToggleServiceV2 } from '@fullyops/legacy/ui/ui-crm/v2/feature-toggles/ui-feature-permission-toggle.service';
import { TenantConfigurationController } from '@fullyops/legacy/ui/ui-crm/ui-tenant-configuration-v2-controller.service';
import { ImageController } from '@fullyops/legacy/ui/ui-crm/ui-image-v2-controller.service';
import { SafeUrl } from '@angular/platform-browser';
import { AuthEndpoint } from '@fullyops/legacy/data/api/v0/AuthEndpoint';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface SubOptions {
  title: string;
  path: string;
  icon: IconDefinition | any;
}

interface SettingsOption {
  title: string;
  icon: IconDefinition;
  subOptions?: SubOptions[];
  permissions?: string[];
  featureToggle?: string;
  path?: string;
}

type SettingsOptions = SettingsOption[];

export const SETTINGS_OPTION = {
  SALES_MANAGEMENT: 'sales',
  SUPPORT_MANAGEMENT: 'support',
  REQUEST_MANAGEMENT: 'requests',
  COMPANY_MANAGEMENT: 'companyManagement',
  ACCESS_TOKENS: 'accessTokens',
  CONTACTS_CONFIGURATIONS: 'contactsConfigurations',
  STOCK: 'stock',
  PARTS: 'parts',
};

export const SETTINGS_SUB_OPTION = {
  [SETTINGS_OPTION.SALES_MANAGEMENT]: {
    GENERAL_CONFIGS: 'generalConfigs',
    REPORT_CONFIGS: 'BREADCRUMB_WORK_ORDER_REPORT_CONFIGURATIONS',
    DATA_MANAGEMENT: 'dataManagement',
  },
  [SETTINGS_OPTION.SUPPORT_MANAGEMENT]: {
    GENERAL_CONFIGS: 'generalConfigs',
    REPORT_CONFIGS: 'BREADCRUMB_WORK_ORDER_REPORT_CONFIGURATIONS',
    WORK_ORDER_CATEGORIES: 'categories',
    DATA_MANAGEMENT: 'dataManagement',
    SCOPE_MANAGEMENT: 'scopeManagement',
  },
  [SETTINGS_OPTION.REQUEST_MANAGEMENT]: {
    GENERAL_CONFIGS: 'generalConfigs',
    REPORT_CONFIGS: 'BREADCRUMB_WORK_ORDER_REPORT_CONFIGURATIONS',
    DATA_MANAGEMENT: 'dataManagement',
  },
  [SETTINGS_OPTION.COMPANY_MANAGEMENT]: {
    ROLES_AND_AUTHORIZATION: 'BREADCRUMB_ROLE_PERMISSIONS',
    USER_MANAGEMENT: 'userManagement',
    INTEGRATIONS: 'integrations',
    PLAN_UPGRADE: 'planUpgrade',
    BILLING: 'billing',
    IMPORT_EXPORT: 'importExport',
    COMPANY_SETTINGS: 'companySettings',
    PARTS: 'parts',
  },
  [SETTINGS_OPTION.CONTACTS_CONFIGURATIONS]: {
    COMPANIES: 'companies',
    CONTACTS: 'contacts',
  },
  [SETTINGS_OPTION.STOCK]: {
    GENERAL_CONFIGS: 'generalConfigs',
  },
  [SETTINGS_OPTION.PARTS]: {
    GENERAL_CONFIGS: 'generalConfigs',
  },
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() isMobileSidebarOpen = false;
  @Output() onMobileSidebarChange = new EventEmitter();

  menuConfigurationHide = environment?.menuConfigurationHide;
  SETTINGS_OPTION = SETTINGS_OPTION;
  cookieLang = '';
  faList = faList;
  faBoxOpen = faBoxOpen;

  settingsOptions: SettingsOptions = [
    {
      title: SETTINGS_OPTION.SALES_MANAGEMENT,
      icon: faTags,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      featureToggle: FeaturePermissionToggleEnum.PIPELINES_SALE_TICKET,
      subOptions: [
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.SALES_MANAGEMENT]
              .GENERAL_CONFIGS,
          path: 'sale-configurations',
          icon: faGear,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.SALES_MANAGEMENT]
              .REPORT_CONFIGS,
          path: 'sale-report-configurations',
          icon: faFileLines,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.SALES_MANAGEMENT]
              .DATA_MANAGEMENT,
          path: '',
          icon: faDatabase,
        },
      ],
    },
    {
      title: SETTINGS_OPTION.SUPPORT_MANAGEMENT,
      icon: faHelmetSafety,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      featureToggle: FeaturePermissionToggleEnum.PIPELINES_SUPPORT_TICKET,
      subOptions: [
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.SUPPORT_MANAGEMENT]
              .GENERAL_CONFIGS,
          path: 'support-configurations/general',
          icon: faGear,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.SUPPORT_MANAGEMENT]
              .REPORT_CONFIGS,
          path: 'support-report-configurations',
          icon: faFileLines,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.SUPPORT_MANAGEMENT]
              .DATA_MANAGEMENT,
          path: 'support-configurations/data-management',
          icon: faDatabase,
        },
      ],
    },
    {
      title: SETTINGS_OPTION.REQUEST_MANAGEMENT,
      icon: faTruckField,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      subOptions: [
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.REQUEST_MANAGEMENT]
              .GENERAL_CONFIGS,
          path: 'request-configurations/general',
          icon: faGear,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.REQUEST_MANAGEMENT]
              .REPORT_CONFIGS,
          path: '',
          icon: faFileLines,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.REQUEST_MANAGEMENT]
              .DATA_MANAGEMENT,
          path: '',
          icon: faDatabase,
        },
      ],
    },
    {
      title: SETTINGS_OPTION.STOCK,
      icon: faBoxesStacked,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      subOptions: [
        {
          title: SETTINGS_SUB_OPTION[SETTINGS_OPTION.STOCK].GENERAL_CONFIGS,
          path: '',
          icon: faGear,
        },
      ],
    },
    {
      title: SETTINGS_OPTION.PARTS,
      icon: faScrewdriverWrench,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      subOptions: [
        {
          title: SETTINGS_SUB_OPTION[SETTINGS_OPTION.PARTS].GENERAL_CONFIGS,
          path: '/part-configurations',
          icon: faGear,
        },
      ],
    },
    {
      title: SETTINGS_OPTION.COMPANY_MANAGEMENT,
      icon: faGear,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      subOptions: [
        {
          title: SETTINGS_SUB_OPTION['companyManagement'].COMPANY_SETTINGS,
          path: '/organization-configurations',
          icon: faGears,
        },
        {
          title:
            SETTINGS_SUB_OPTION['companyManagement'].ROLES_AND_AUTHORIZATION,
          path: 'roles-permissions',
          icon: faUserLock,
        },
        {
          title: SETTINGS_SUB_OPTION['companyManagement'].USER_MANAGEMENT,
          path: '/users',
          icon: faUsersGear,
        },
        {
          title: SETTINGS_SUB_OPTION['companyManagement'].INTEGRATIONS,
          path: '',
          icon: faCircleNodes,
        },
        {
          title: SETTINGS_SUB_OPTION['companyManagement'].PLAN_UPGRADE,
          path: '',
          icon: faCircleArrowUp,
        },
        {
          title: SETTINGS_SUB_OPTION['companyManagement'].BILLING,
          path: '',
          icon: faReceipt,
        },
        {
          title: SETTINGS_SUB_OPTION['companyManagement'].IMPORT_EXPORT,
          // path: 'companies/contacts-import',
          path: '',
          icon: faFileImport,
        },
      ],
    },
    {
      title: SETTINGS_OPTION.CONTACTS_CONFIGURATIONS,
      icon: faGears,
      permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
      subOptions: [
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.CONTACTS_CONFIGURATIONS]
              .COMPANIES,
          path: 'companies-settings',
          icon: faBuilding,
        },
        {
          title:
            SETTINGS_SUB_OPTION[SETTINGS_OPTION.CONTACTS_CONFIGURATIONS]
              .CONTACTS,
          path: '',
          icon: faUsers,
        },
        // {
        //   title: SETTINGS_SUB_OPTION[SETTINGS_OPTION.CONTACTS_CONFIGURATIONS].ACTIVITIES,
        //   path: '',
        //   icon: faListCheck,
        // },
      ],
    },
    // {
    //   title: SETTINGS_OPTION.GENERAL_SETTINGS,
    //   icon: faGears,
    //   permissions: [PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS],
    //   subOptions: [
    //     {
    //       title: SETTINGS_SUB_OPTION.generalSettings.COMPANIES,
    //       path: 'companies-settings',
    //       icon: faBuilding,
    //     },
    //     {
    //       title: SETTINGS_SUB_OPTION.generalSettings.ACTIVITIES,
    //       path: '',
    //       icon: faListCheck,
    //     },
    //     {
    //       title: SETTINGS_SUB_OPTION.generalSettings.CONTACTS,
    //       path: '',
    //       icon: faUsers,
    //     },
    //   ],
    // },
  ];

  salesManagementOptions = this.settingsOptions[0];
  supportManagementOptions = this.settingsOptions[1];
  requestsManagementOptions = this.settingsOptions[2];
  companyManagementOptions = this.settingsOptions[5];
  stockOptions = this.settingsOptions[3];
  partsOptions = this.settingsOptions[4];
  contactsConfigurationsOptions = this.settingsOptions[6];

  accessTokenOptions: SettingsOption = {
    icon: faKey,
    title: SETTINGS_OPTION['ACCESS_TOKENS'],
    permissions: [PermissionType.CAN_ACCESS_API_TOKENS],
    path: 'access-tokens',
  };

  faTags = faTags;
  faUserGear = faUserGear;
  faTruckField = faTruckField;
  faGear = faGear;
  faUser = faUser;
  faPowerOff = faPowerOff;
  faAddressBook = faAddressBook;
  faBuilding = faBuilding;
  faListCheck = faListCheck;
  faKey = faKey;

  CAN_EDIT_TENANT_CONFIGURATIONS = [
    PermissionType.CAN_EDIT_TENANT_CONFIGURATIONS,
  ];

  usersPermission = [PermissionType.CAN_EDIT_USERS];
  tenantData$ = new BehaviorSubject<TenantResponse>(null);
  tenantGeneralLogoUrl: SafeUrl = null;
  isMobileScreen = false;

  constructor(
    private router: Router,
    public authService: UiAuthService,
    public authEndpoint: AuthEndpoint,
    private tenantControllerV2: TenantControllerV2,
    private ref: ChangeDetectorRef,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    private featurePermissionToggleService: FeaturePermissionToggleServiceV2,
    private tenantConfigurationController: TenantConfigurationController,
    public imageController: ImageController,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe(({ breakpoints }) => {
        this.isMobileScreen = breakpoints[Breakpoints.XSmall];
      });
  }

  ngOnInit() {
    this.loadFeatureToggle();

    this.tenantConfigurationController
      .getConfigurationsByLabel({ label: 'GENERAL_TENANT_LOGO' })
      .subscribe((res) => {
        if (!res.image) return;
        this.tenantGeneralLogoUrl = res.image.file.signedUrl;
        this.ref.detectChanges();
      });

    this.tenantControllerV2.getCurrentTenant().subscribe((value) => {
      this.tenantData$.next(value);
      this.ref.detectChanges();
    });
  }

  loadFeatureToggle() {
    this.featurePermissionToggleService.getAllV2().subscribe((res) => {});
  }

  logout() {
    this.authService.logout();
    this.authEndpoint.logout();
    this.router.navigate(['/login']);
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  getTextUserRole(user: User) {
    let text = '';
    user.roles.forEach((role) => {
      if (text) {
        text = text + ', ' + role.name;
      } else {
        text = role.name;
      }
    });
    return text;
  }

  isMenuConfigurationHide(optionName: string) {
    return !this.menuConfigurationHide?.[optionName]?.hide;
  }

  isMenuConfigurationDisabled(optionName: string) {
    return this.menuConfigurationHide?.[optionName]?.disabled;
  }
}
