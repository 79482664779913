import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SideBarAnimation } from './sidebar.animation';
import { FeaturePermissionToggle, PermissionType } from '@fullyops/legacy/ui';
import {
  faArrowLeft,
  faArrowRight,
  faBoxesStacked,
  faBoxOpen,
  faBuilding,
  faHelmetSafety,
  faHouse,
  faLanguage,
  faListCheck,
  faScrewdriverWrench,
  faSquarePollVertical,
  faTags,
  faToolbox,
  faTruckField,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { ComponentPortal } from '@angular/cdk/portal';
import { I18NextService } from 'angular-i18next';
import { environment } from '../../../../environments/environment';
import { SidenavPortalBridgeService } from '@fullyops/core/sidenav-form-portal-bridge.service';

export const SIDE_BAR_OPTIONS = {
  HOME: 'home',
  INSIGHTS: 'insights',
  SALES: 'sales',
  SALE_OPPORTUNITIES: 'saleOpportunities',
  SUPPORT: 'support',
  REQUESTS: 'requests',
  PRODUCTS: 'products',
  PARTS: 'parts',
  PEOPLE: 'people',
  COMPANIES: 'companies',
  ACTIVITIES: 'activities',
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [SideBarAnimation.AnimTrigger],
})
export class SideBarComponent implements OnInit {
  @Input() isMobileSidebarOpen = false;
  @Output() onMobileSidebarChange = new EventEmitter();
  expandedWidth = '150px';

  constructor(
    private breakpointObserver: BreakpointObserver,
    protected sidenavPortalBridgeService: SidenavPortalBridgeService,
    private i18nextService: I18NextService
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe(({ breakpoints }) => {
        this.isMobileScreen$.next(breakpoints[Breakpoints.XSmall]);
      });
  }

  sideBarConfigurationHide = environment?.sideBarConfigurationHide;

  collapsed = true;
  faLanguage = faLanguage;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  isMobileScreen$ = new BehaviorSubject(false);

  @ViewChild('sidenavForm') sidenavForm: MatSidenav;
  portal$: Observable<ComponentPortal<any>>;

  closeSidebarForm() {
    this.sidenavPortalBridgeService.closePortal();
  }

  systemSections = [
    {
      name: '',
      sections: [
        {
          name: SIDE_BAR_OPTIONS.HOME,
          path: '/',
          icon: faHouse,
          pathMatchOptions: {
            matrixParams: 'ignored',
            queryParams: 'subset',
            paths: 'exact',
            fragment: 'ignored',
          },
          toolTipText: 'homePage',
        },
        {
          name: 'activities',
          path: '/activities',
          permissions: [
            PermissionType.CAN_ACCESS_ACTIVITIES,
            PermissionType.CAN_ACCESS_OWN_ACTIVITIES,
          ],
          icon: faListCheck,
          testId: 'activities-btn',
        },
        {
          name: SIDE_BAR_OPTIONS.INSIGHTS,
          path: '/insights',
          icon: faSquarePollVertical,
          exactPath: true,
          toolTipText: 'insights',
        },
      ],
    },
    {
      name: 'contacts',
      sections: [
        {
          name: SIDE_BAR_OPTIONS.PEOPLE,
          path: '/contacts',
          permissions: [PermissionType.CAN_ACCESS_CONTACTS],
          exactPath: false,
          toolTipText: 'people',
          icon: faUsers,
        },
        {
          name: SIDE_BAR_OPTIONS.COMPANIES,
          path: '/companies',
          permissions: [PermissionType.CAN_ACCESS_COMPANIES],
          exactPath: false,
          toolTipText: 'companies',
          icon: faBuilding,
        },
      ],
    },
    {
      name: 'pipelines',
      sections: [
        {
          name: SIDE_BAR_OPTIONS.SALES,
          path: '/sale-opportunities',
          icon: faTags,
          permissions: [
            PermissionType.CAN_ACCESS_SALE_TICKETS,
            PermissionType.CAN_ACCESS_OWN_SALE_TICKETS,
          ],
          featureToggle: FeaturePermissionToggle.SALE_OPPORTUNITIES,
          toolTipText: 'saleOpportunities',
        },
        {
          name: SIDE_BAR_OPTIONS.SUPPORT,
          path: '/tickets/support',
          icon: faHelmetSafety,
          permissions: [
            PermissionType.CAN_ACCESS_SUPPORT_TICKETS,
            PermissionType.CAN_ACCESS_OWN_SUPPORT_TICKETS,
          ],
          featureToggle: FeaturePermissionToggle.PIPELINES_SUPPORT_TICKET,
          toolTipText: 'support',
        },
        {
          name: SIDE_BAR_OPTIONS.REQUESTS,
          path: '/tickets/request',
          icon: faTruckField,
          permissions: [
            PermissionType.CAN_ACCESS_REQUEST_TICKETS,
            PermissionType.CAN_ACCESS_OWN_REQUEST_TICKETS,
          ],
          featureToggle: FeaturePermissionToggle.PIPELINES_SUPPORT_TICKET,
          toolTipText: 'requests',
        },
      ],
    },
    {
      name: 'stock',
      sections: [
        {
          name: SIDE_BAR_OPTIONS.PRODUCTS,
          path: '/product-configurations',
          permissions: [PermissionType.CAN_ACCESS_PRODUCTS],
          featureToggle: FeaturePermissionToggle.PIPELINES_SALE_TICKET,
          exactPath: false,
          toolTipText: 'productsStock',
          icon: faBoxesStacked,
        },
        {
          name: SIDE_BAR_OPTIONS.PARTS,
          path: '/stock',
          permissions: [PermissionType.CAN_ACCESS_PARTS],
          featureToggle: FeaturePermissionToggle.PIPELINES_SUPPORT_TICKET,
          exactPath: false,
          toolTipText: 'partsStock',
          icon: faToolbox,
        },
      ],
    },
    {
      name: 'catalog',
      sections: [
        {
          name: SIDE_BAR_OPTIONS.PRODUCTS,
          path: '/products',
          permissions: [PermissionType.CAN_ACCESS_PRODUCT_CONFIGURATIONS],
          featureToggle: FeaturePermissionToggle.PIPELINES_SALE_TICKET,
          exactPath: false,
          toolTipText: 'productsCatalog',
          icon: faBoxOpen,
        },
        {
          name: SIDE_BAR_OPTIONS.PARTS,
          path: '/parts',
          permissions: [PermissionType.CAN_ACCESS_PARTS],
          featureToggle: FeaturePermissionToggle.PIPELINES_SUPPORT_TICKET,
          exactPath: false,
          toolTipText: 'partsCatalog',
          icon: faScrewdriverWrench,
        },
      ],
    },
  ];

  sideBarSizeByLanguage = {
    en: '150px',
    pt: '210px',
  };

  ngOnInit() {
    this.portal$ = this.sidenavPortalBridgeService.portal$;
    this.portal$.subscribe((value) => {
      if (!this.sidenavForm) return;

      if (!!value) {
        this.sidenavForm.open();
      } else {
        this.sidenavForm.close();
      }
    });

    this.i18nextService.events.languageChanged.subscribe(() => {
      this.adjustSidebarWidth();
    });

    this.adjustSidebarWidth();
  }

  adjustSidebarWidth() {
    const currentLanguage = this.i18nextService.language;
    this.expandedWidth = this.sideBarSizeByLanguage[currentLanguage] || '150px';
    this.detectChange();
  }

  collapse() {
    this.collapsed = true;
    this.detectChange();
  }

  expand() {
    this.collapsed = false;
    this.detectChange();
  }

  toggleExpand() {
    this.collapsed = !this.collapsed;
    this.detectChange();
  }

  detectChange() {}

  isConfigurationDisabled(pageName: string) {
    return this.sideBarConfigurationHide[pageName]?.disabled == true;
  }
}
