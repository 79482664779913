<mat-toolbar color="primary" class="mat-elevation-z6">
  <mat-toolbar-row>
    <div class="mobile-menu-button">
      <button mat-icon-button (click)="onMobileSidebarChange.emit()">
        <mat-icon>{{ isMobileSidebarOpen ? 'close' : 'menu' }}</mat-icon>
      </button>
    </div>
    <div class="fullyops-logo">
      <a *ngIf="!isMobileScreen" routerLink="/">
        <img
          src="../../../../assets/svg/fullyops-logo-horizontal.svg"
          alt="logo"
        />
      </a>
      <a *ngIf="isMobileScreen" routerLink="/">
        <img src="../../../../assets/svg/favicon.svg" alt="logo" />
      </a>
    </div>
    <ng-container *ngIf="authService.currentUser$ | async as user">
      <div class="app-bar-right">
        <button
          mat-stroked-button
          [matMenuTriggerFor]="settings"
          class="settings-button"
          [attr.data-test-id]="'menu-option'"
        >
          <div class="settings-button-div">
            <div class="image-container">
              <img
                [src]="
                  tenantGeneralLogoUrl
                    ? tenantGeneralLogoUrl
                    : (tenantData$ | async).logo
                "
                alt="tenant"
                *ngIf="tenantGeneralLogoUrl || tenantData$.value"
              />
            </div>
            <mat-icon>more_vert</mat-icon>
          </div>
        </button>
      </div>
      <mat-menu #settings="matMenu" class="settings" xPosition="before">
        <div class="menu-user-header" [routerLink]="'/me'">
          <user-profile-image-component
            [user]="user"
            [includeBorder]="true"
            [size]="30"
          ></user-profile-image-component>
          <div>
            <span>{{ user.firstName }} {{ user.lastName }}</span>
            <div class="menu-user-header-roles">
              <ng-container *ngFor="let role of user.roles; let i = index">
                <span *ngIf="i > 0">|</span>
                <span>{{ role.name | i18nextCap }}</span>
              </ng-container>
            </div>
          </div>
          <fa-icon
            [icon]="companyManagementOptions.icon"
            class="icon-color"
          ></fa-icon>
        </div>
        <mat-divider></mat-divider>

        <!-- companyManagement -->
        <button
          mat-menu-item
          [matMenuTriggerFor]="companyManagement"
          [hideIfNotAllowed]="$any(['CAN_EDIT_TENANT_CONFIGURATIONS'])"
          *ngIf="isMenuConfigurationHide(SETTINGS_OPTION.COMPANY_MANAGEMENT)"
        >
          <fa-icon
            [icon]="companyManagementOptions.icon"
            class="icon-color"
          ></fa-icon>
          {{ companyManagementOptions.title | i18nextCap }}
        </button>
        <mat-menu #companyManagement="matMenu" xPosition="before">
          <ng-container
            *ngFor="let subOption of companyManagementOptions.subOptions"
          >
            <button
              *ngIf="!menuConfigurationHide?.companyManagement?.hideSubOption?.[subOption.title]"
              mat-menu-item
              (click)="navigate(subOption.path)"
              [disabled]="subOption.path === ''"
            >
              <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
              <span>{{ subOption.title | i18nextCap }}</span>
            </button>
          </ng-container>
        </mat-menu>

        <!-- configurations -->
        <button
          mat-menu-item
          [matMenuTriggerFor]="configurations"
          [hideIfNotAllowed]="CAN_EDIT_TENANT_CONFIGURATIONS"
        >
          <fa-icon [icon]="faGear" class="icon-color"></fa-icon>
          {{ 'configurations' | i18nextCap }}
        </button>
        <mat-menu #configurations="matMenu" xPosition="before">
          <!-- Pipelines -->
          <button
            mat-menu-item
            [matMenuTriggerFor]="pipelines"
            [hideIfNotAllowed]="CAN_EDIT_TENANT_CONFIGURATIONS"
          >
            <fa-icon [icon]="faList" class="icon-color"></fa-icon>
            {{ 'pipelinesConfiguration' | i18nextCap }}
          </button>

          <mat-menu #pipelines="matMenu" xPosition="before">
            <!-- salesManagement -->
            <button
              mat-menu-item
              [matMenuTriggerFor]="salesManagement"
              [hideIfNotAllowed]="$any(salesManagementOptions.permissions)"
              [featureToggle]="$any(salesManagementOptions.featureToggle)"
              *ngIf="isMenuConfigurationHide(SETTINGS_OPTION.SALES_MANAGEMENT)"
            >
              <fa-icon
                [icon]="salesManagementOptions.icon"
                class="icon-color"
              ></fa-icon>
              {{ salesManagementOptions.title | i18nextCap }}
            </button>
            <mat-menu #salesManagement="matMenu" xPosition="before">
              <ng-container
                *ngFor="let subOption of salesManagementOptions.subOptions"
              >
                <button
                  *ngIf="!menuConfigurationHide?.salesManagement?.hideSubOption?.[subOption.title]"
                  mat-menu-item
                  (click)="navigate(subOption.path)"
                  [disabled]="subOption.path === ''"
                >
                  <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
                  <span>{{ subOption.title | i18nextCap }}</span>
                </button>
              </ng-container>
            </mat-menu>

            <!-- supportManagement -->
            <button
              mat-menu-item
              [matMenuTriggerFor]="supportManagement"
              [hideIfNotAllowed]="$any(supportManagementOptions.permissions)"
              [featureToggle]="$any(supportManagementOptions.featureToggle)"
              *ngIf="
                isMenuConfigurationHide(SETTINGS_OPTION.SUPPORT_MANAGEMENT)
              "
            >
              <fa-icon
                [icon]="supportManagementOptions.icon"
                class="icon-color"
              ></fa-icon>
              {{ supportManagementOptions.title | i18nextCap }}
            </button>
            <mat-menu #supportManagement="matMenu" xPosition="before">
              <ng-container
                *ngFor="let subOption of supportManagementOptions.subOptions"
              >
                <button
                  *ngIf="!menuConfigurationHide?.supportManagement?.hideSubOption?.[subOption.title]"
                  mat-menu-item
                  (click)="navigate(subOption.path)"
                  [disabled]="subOption.path === ''"
                >
                  <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
                  <span>{{ subOption.title | i18nextCap }}</span>
                </button>
              </ng-container>
            </mat-menu>

            <!-- requestManagement -->
            <button
              mat-menu-item
              [matMenuTriggerFor]="requestManagement"
              [hideIfNotAllowed]="$any(requestsManagementOptions.permissions)"
              *ngIf="
                isMenuConfigurationHide(SETTINGS_OPTION.REQUEST_MANAGEMENT)
              "
            >
              <fa-icon
                [icon]="requestsManagementOptions.icon"
                class="icon-color"
              ></fa-icon>
              {{ requestsManagementOptions.title | i18nextCap }}
            </button>
            <mat-menu #requestManagement="matMenu" xPosition="before">
              <ng-container
                *ngFor="let subOption of requestsManagementOptions.subOptions"
              >
                <button
                  *ngIf="!menuConfigurationHide?.requestManagement?.hideSubOption?.[subOption.title]"
                  mat-menu-item
                  (click)="navigate(subOption.path)"
                  [disabled]="subOption.path === ''"
                >
                  <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
                  <span>{{ subOption.title | i18nextCap }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </mat-menu>

          <!-- stock -->
          <button
            mat-menu-item
            [matMenuTriggerFor]="stock"
            [hideIfNotAllowed]="$any(stockOptions.permissions)"
            *ngIf="isMenuConfigurationHide(SETTINGS_OPTION.STOCK)"
          >
            <fa-icon [icon]="stockOptions.icon" class="icon-color"></fa-icon>
            {{ stockOptions.title | i18nextCap }}
          </button>
          <mat-menu #stock="matMenu" xPosition="before">
            <ng-container *ngFor="let subOption of stockOptions.subOptions">
              <button
                *ngIf="!menuConfigurationHide?.generalManagement?.hideSubOption?.[subOption.title]"
                mat-menu-item
                (click)="navigate(subOption.path)"
                [disabled]="subOption.path === ''"
              >
                <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
                <span>{{ subOption.title | i18nextCap }}</span>
              </button>
            </ng-container>
          </mat-menu>

          <!-- parts -->
          <button
            mat-menu-item
            [matMenuTriggerFor]="parts"
            [hideIfNotAllowed]="$any(partsOptions.permissions)"
            *ngIf="isMenuConfigurationHide(SETTINGS_OPTION.PARTS)"
          >
            <fa-icon [icon]="partsOptions.icon" class="icon-color"></fa-icon>
            {{ partsOptions.title | i18nextCap }}
          </button>
          <mat-menu #parts="matMenu" xPosition="before">
            <ng-container *ngFor="let subOption of partsOptions.subOptions">
              <button
                *ngIf="!menuConfigurationHide?.generalManagement?.hideSubOption?.[subOption.title]"
                mat-menu-item
                (click)="navigate(subOption.path)"
              >
                <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
                <span>{{ subOption.title | i18nextCap }}</span>
              </button>
            </ng-container>
          </mat-menu>

          <!-- catalog configuration -->
          <button mat-menu-item [disabled]="true">
            <fa-icon [icon]="faBoxOpen" class="icon-color"></fa-icon>
            {{ 'catalogConfigurations' | i18nextCap }}
          </button>

          <!-- contactsConfigurations -->
          <button
            mat-menu-item
            [matMenuTriggerFor]="contactsConfigurations"
            [hideIfNotAllowed]="$any(contactsConfigurationsOptions.permissions)"
            *ngIf="isMenuConfigurationHide(SETTINGS_OPTION.REQUEST_MANAGEMENT)"
          >
            <fa-icon
              [icon]="contactsConfigurationsOptions.icon"
              class="icon-color"
            ></fa-icon>
            {{ contactsConfigurationsOptions.title | i18nextCap }}
          </button>
          <mat-menu #contactsConfigurations="matMenu" xPosition="before">
            <ng-container
              *ngFor="let subOption of contactsConfigurationsOptions.subOptions"
            >
              <button
                *ngIf="!menuConfigurationHide?.generalManagement?.hideSubOption?.[subOption.title]"
                mat-menu-item
                (click)="navigate(subOption.path)"
                [disabled]="subOption.path === ''"
              >
                <fa-icon [icon]="subOption.icon" class="icon-color"></fa-icon>
                <span>{{ subOption.title | i18nextCap }}</span>
              </button>
            </ng-container>
          </mat-menu>

          <!-- activities -->
          <button mat-menu-item [disabled]="true">
            <fa-icon [icon]="faListCheck" class="icon-color"></fa-icon>
            {{ 'activitiesConfigurations' | i18nextCap }}
          </button>
        </mat-menu>

        <button
          mat-menu-item
          (click)="logout()"
          [attr.data-test-id]="'button-logout'"
        >
          <fa-icon [icon]="faPowerOff" class="icon-color"></fa-icon>
          {{ 'logout' | i18nextCap }}
        </button>
      </mat-menu>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
