import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

export const SideBarAnimation = {
  AnimTrigger: [
    trigger('expandedCollapsed', [
      state(
        'expanded',
        style({
          width: '{{ expandedWidth }}',
        }),
        { params: { expandedWidth: '150px' } }
      ),
      state(
        'collapsed',
        style({
          width: '85px',
        })
      ),
      state(
        'contentExpanded',
        style({
          'margin-left': '{{ expandedWidth }}',
        }),
        { params: { expandedWidth: '150px' } }
      ),
      state(
        'contentCollapsed',
        style({
          'margin-left': '85px',
        })
      ),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
      transition(
        'contentCollapsed <=> contentExpanded',
        animate('300ms ease-in-out')
      ),
      transition('ulCollapsed <=> ulExpanded', animate('300ms ease-in-out')),
    ]),
  ],
};
